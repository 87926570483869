'use client';

import Script from 'next/script';
import React from 'react';
import { Origin } from '@weski-monorepo/types';

interface Props {
  origin: Origin;
}

const Accessibe: React.FC<Props> = ({ origin }) => {
  return origin?.id === 'il' ? (
    <Script id="accessibe-script" strategy="lazyOnload">
      {`
            (function() {
                var s = document.createElement('script');
                var h = document.querySelector('head') || document.body;

                s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
                s.async = true;

                s.onload = function() {
                    acsbJS.init({
                    statementLink: '',
                    footerHtml: '',
                    hideMobile: false,
                    hideTrigger: false,
                    disableBgProcess: false,
                    language: 'en',
                    position: 'left',
                    leadColor: '#146FF8',
                    triggerColor: '#000000',
                    triggerRadius: '50%',
                    triggerPositionX: 'right',
                    triggerPositionY: 'top',
                    triggerIcon: 'people',
                    triggerSize: 'small',
                    triggerOffsetX: 12,
                    triggerOffsetY: 260,
                    mobile: {
                        triggerSize: 'small',
                        triggerPositionX: 'left',
                        triggerPositionY: 'bottom',
                        triggerOffsetX: 4,
                        triggerOffsetY: 80,
                        triggerRadius: '50%',
                    },
                    });
                };

                h.appendChild(s);
            })();
        `}
    </Script>
  ) : null;
};

export default Accessibe;
